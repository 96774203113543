<template>
    <div>
        <div class="table_box">
            通知
        </div>
    </div>
</template>

<script>
    export default {
        name: "index"
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";

</style>